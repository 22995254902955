// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-calendar-page-js": () => import("/opt/build/repo/src/templates/calendar-page.js" /* webpackChunkName: "component---src-templates-calendar-page-js" */),
  "component---src-templates-home-page-js": () => import("/opt/build/repo/src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-mixes-page-js": () => import("/opt/build/repo/src/templates/mixes-page.js" /* webpackChunkName: "component---src-templates-mixes-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("/opt/build/repo/src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-mixes-old-js": () => import("/opt/build/repo/src/pages/mixes-old.js" /* webpackChunkName: "component---src-pages-mixes-old-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

